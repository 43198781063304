export default {
  adUnitPath: '/124312541,2820490/Pitchero/ros_topleader',
  size: [
    [970, 250],
    [970, 90],
    [728, 90],
    [728, 90],
    [320, 50],
    [300, 50],
  ],
  id: 'ros-top-banner',
  targeting: {
    Pitchero_Ad_Position: ['ATF'],
    Pitchero_Ad_Size: ['top_banner'],
  },
  responsiveSizes: [
    {
      breakpoint: [961, 250],
      sizes: [
        [970, 250],
        [970, 90],
        [728, 90],
      ],
    },
    {
      breakpoint: [728, 90],
      sizes: [728, 90],
    },
    {
      breakpoint: [0, 0],
      sizes: [
        [320, 50],
        [300, 50],
      ],
    },
  ],
};
