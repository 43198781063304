import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import adConfig from 'lib/advertising/slots/ros-bottom-banner';
import { getCurrentClubIncludesAdverts } from 'store/clubs/selectors';
import Advert from './advert';

const RosBottomBanner = ({ className, includeAdverts, style: customStyle, uid }) => {
  if (!includeAdverts) return null;

  const adProps = {};
  if (uid) {
    adProps.key = uid;
  }

  return <Advert adConfig={adConfig} className={className} style={customStyle} {...adProps} />;
};

RosBottomBanner.propTypes = {
  className: PropTypes.string,
  includeAdverts: PropTypes.bool.isRequired,
  style: PropTypes.shape(),
  uid: PropTypes.string,
};
RosBottomBanner.defaultProps = {
  className: null,
  style: {},
  uid: null,
};
const mapStateToProps = (state) => ({
  includeAdverts: getCurrentClubIncludesAdverts(state),
});
export default connect(mapStateToProps)(RosBottomBanner);
