import React from 'react';
import PropTypes from 'prop-types';
import { Space, Grid, Typography } from '@pitchero/react-ui';
import { Trans } from 'lib/i18n';
import Official from './official';

const OfficialGroup = ({ officialGroup, onClickEmail }) => {
  return (
    <>
      <Space bottom="medium">
        <Space bottom="small">
          <Typography
            preset="tab--active"
            color="primary"
            component="div"
            style={{ textTransform: 'uppercase' }}
            responsive={[{ maxWidth: 'desk', props: { preset: 'tag--large' } }]}
          >
            <Trans i18nKey="common:contact_subtitle">Contact</Trans>
          </Typography>
        </Space>
        <Typography preset="title" component="div" color="black">
          {officialGroup.name}
        </Typography>
      </Space>
      {officialGroup.officials.length && (
        <Grid
          className="ie-grid-two-col"
          columns="1fr 1fr 1fr"
          rowGap="medium"
          columnGap="medium"
          responsive={[
            { minWidth: 895, maxWidth: 1319, props: { columns: '1fr 1fr', rowGap: 'small' } },
            { maxWidth: 894, props: { columns: '1fr', rowGap: 'small' } },
            {
              maxWidth: 400,
              props: { columns: 'repeat(auto-fit, minmax(250px, 376px))', rowGap: 'small' },
            },
          ]}
        >
          {officialGroup.officials.map((official) => (
            <Official
              key={`${official.id}${official.position}`}
              official={official}
              onClickEmail={onClickEmail}
            />
          ))}
        </Grid>
      )}
    </>
  );
};

OfficialGroup.defaultProps = {
  onClickEmail: null,
};

OfficialGroup.propTypes = {
  officialGroup: PropTypes.shape().isRequired,
  onClickEmail: PropTypes.func,
};

export default OfficialGroup;
