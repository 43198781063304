export default (values) => {
  const errors = {};

  const isEmailInvalid = (value) =>
    !!(value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value));

  if (!values.name || values.name.length < 1) {
    errors.name = 'Name is required';
  }

  if (!values.message || values.message.length < 1) {
    errors.name = 'Message is required';
  }

  if (!values.email) {
    errors.email = 'Email address is required';
  } else if (isEmailInvalid(values.email)) {
    errors.email = 'Invalid email address';
  }

  return errors;
};
