import React from 'react';
import PropTypes from 'prop-types';
import RosTopBanner from 'components/adverts/ros-top-banner';

// eslint-disable-next-line no-unused-vars
const TopBannerVideoPlayerSwitch = ({ advertisingTier, pageType, sportId, uid }) => {
  /*
  if (advertisingTier === 1) {
    // FREE tier websites get the video player, others get the TopBanner
    // @TODO: Replace with PS video player once received
    return (
      <MaxWidth component="div" maxWidth={450} center>
        <MinuteMediaPlayer
          advertisingTier={advertisingTier}
          key={pageType}
          pageType={pageType}
          sportId={sportId}
        />
      </MaxWidth>
    );
  }
  */

  return <RosTopBanner />;
};

TopBannerVideoPlayerSwitch.defaultProps = {
  uid: null,
};

TopBannerVideoPlayerSwitch.propTypes = {
  advertisingTier: PropTypes.number.isRequired,
  pageType: PropTypes.string.isRequired,
  sportId: PropTypes.number.isRequired,
  uid: PropTypes.string,
};

export default TopBannerVideoPlayerSwitch;
