import { find, flatMap, get, includes } from 'lodash';

export const isClubLoading = (state, clubId) => {
  return includes(get(state, 'contact.currentlyLoadingClubs', []), clubId);
};

export const hasClubLoaded = (state, clubId) => {
  return includes(get(state, 'contact.loadedClubs', []), clubId);
};

export const getClubLocations = (state, clubId) => get(state, `contact.locations[${clubId}]`, []);

export const getOfficialGroups = (state, clubId) =>
  get(state, `contact.officialGroups[${clubId}]`, []);

export const getOtherOfficials = (state, clubId) =>
  get(state, `contact.otherOfficials[${clubId}]`, []);

export const getClubLoadingError = (state, clubId) =>
  get(state, `contact.loadingErrors[${clubId}]`);

export const isAttemptingToContactOfficial = (state) => {
  return get(state, `contact.contactingOfficial`, false);
};

export const getOfficial = (state, clubId, officialId) => {
  const officials = flatMap(getOfficialGroups(state, clubId), (group) => group.officials);
  const otherOfficials = getOtherOfficials(state, clubId);
  const allOfficials = [...officials, ...otherOfficials];

  return find(allOfficials, { id: officialId });
};
